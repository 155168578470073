const supplierTeamAdminRoutes = {
  path: "/supplier_team/admin",
  component: () => import("layouts/SupplierTeamAdminPortalLayout.vue"),
  children: [
    {
      path: "profile",
      name: "SupplierAdminProfile",
      component: () => import("pages/supplierTeam/SupplierAdminProfile.vue"),
    },
    {
      path: "",
      name: "SupplierAdminSupplierList",
      component: () =>
        import("pages/supplierTeam/SupplierAdminSupplierList.vue"),
    },
    {
      path: "sellers/add",
      name: "SupplierAdminAddSeller",
      component: () => import("pages/supplierTeam/SupplierAdminAddSeller.vue"),
    },
    {
      path: "sellers/:sellerId",
      name: "SupplierAdminViewSeller",
      component: () => import("pages/supplierTeam/SupplierAdminViewSeller.vue"),
    },
    {
      path: "sellers/:sellerId/edit",
      name: "SupplierAdminEditSeller",
      component: () => import("pages/supplierTeam/SupplierAdminEditSeller.vue"),
    },
    {
      path: "sales_orders/:salesOrderId",
      name: "SupplierAdminViewSalesOrder",
      component: () =>
        import("pages/supplierTeam/SupplierAdminViewSalesOrder.vue"),
    },
    {
      path: "sales_orders",
      name: "SupplierAdminSalesOrdersSearch",
      component: () => import("layouts/orders/SupplyAdminSalesOrderLayout.vue"),
      children: [
        {
          path: "",
          name: "SupplierAdminSalesOrdersSearch",
          component: () =>
            import("pages/supplierTeam/SupplierAdminSalesOrdersSearch.vue"),
        },
        {
          path: "qc/reports",
          name: "SupplierAdminQCReports",
          component: () =>
            import("pages/supplierTeam/SupplierAdminQCReports.vue"),
        },
        {
          path: "dispatch/calendar",
          name: "SupplierAdminOrderDispatchCalendar",
          component: () =>
            import("pages/supplierTeam/SupplierAdminOrderDispatchCalendar.vue"),
        },
      ],
    },
  ],
};

export default supplierTeamAdminRoutes;
