const supplierTeamAgentRoutes = {
  path: "/supplier_team/agent",
  component: () => import("layouts/SupplierTeamAgentPortalLayout.vue"),
  children: [
    {
      path: "profile",
      name: "SupplierAgentProfile",
      component: () => import("pages/supplierTeam/SupplierAgentProfile.vue"),
    },
    {
      path: "",
      name: "SupplierAgentSupplierList",
      component: () =>
        import("pages/supplierTeam/SupplierAgentSupplierList.vue"),
    },
    {
      path: "sellers/add",
      name: "SupplierTeamAddSeller",
      component: () => import("pages/supplierTeam/SupplierAgentAddSeller.vue"),
    },

    {
      path: "sellers/:sellerId",
      name: "SupplierAgentViewSeller",
      component: () => import("pages/supplierTeam/SupplierAgentViewSeller.vue"),
    },
  ],
};

export default supplierTeamAgentRoutes;
