const redirectRoutes = {
  path: "/redirect/",
  children: [
    {
      path: "buyer/:buyerId",
      component: () => import("src/pages/redirectTo/RedirectToBuyerPage.vue"),
      name: "RedirectToBuyerPage",
    },
    {
      path: "master_orders/:salesOrderId",
      component: () =>
        import("src/pages/redirectTo/RedirectToMasterSalesPage.vue"),
      name: "RedirectToLegacySalesOrderPage",
    },
    {
      path: "sales_orders/:salesOrderId",
      component: () =>
        import("src/pages/redirectTo/RedirectToMasterSalesPage.vue"),
      name: "RedirectToSalesOrderPage",
    },
    {
      path: "sample_orders/:sampleOrderId",
      component: () =>
        import("src/pages/redirectTo/RedirectToSampleOrderPage.vue"),
      name: "RedirectToSampleOrderPage",
    },
  ],
};

export default redirectRoutes;
