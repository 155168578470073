const sellerRoutes = {
  path: "/seller/",
  component: () => import("layouts/SellerPortalLayout.vue"),
  children: [
    {
      path: ":sellerCompanyId/company",
      name: "SellerManageCompany",
      component: () => import("pages/seller/SellerManageCompany.vue"),
    },
    {
      path: "categories/:categoryId/sku/create",
      name: "SellerCreateSKU",
      component: () => import("pages/seller/CreateSKU.vue"),
    },
    {
      path: "categories/:categoryId/sku/:skuId",
      name: "SellerViewSKU",
      component: () => import("pages/seller/SellerSKU.vue"),
    },

    {
      path: "categories/:categoryId/sku/:skuId/edit",
      name: "SellerEditSKU",
      component: () => import("pages/seller/SellerSKUEdit.vue"),
    },
    {
      path: "catalog",
      name: "SellerViewCatalog",
      component: () => import("src/pages/seller/SellerCatalog.vue"),
    },

    {
      path: "sales_orders",
      name: "SellerViewSalesOrders",
      component: () => import("pages/seller/SellerSalesOrders.vue"),
    },
    {
      path: "sales_orders/:salesOrderId",
      name: "SellerViewSalesOrder",
      component: () => import("pages/seller/SellerViewSalesOrder.vue"),
    },

    {
      path: "profile",
      name: "SellerProfile",
      component: () => import("src/pages/seller/SellerProfile.vue"),
    },
  ],
};

export default sellerRoutes;
