const buyerRoutes = {
  path: "/buyer/",
  component: () => import("layouts/BuyerPortalLayout.vue"),
  children: [
    {
      path: ":buyerCompanyId/company",
      name: "BuyerManageCompany",
      component: () => import("pages/buyer/BuyerManageCompany.vue"),
    },
    {
      path: "skus",
      name: "BuyerBuyerSKUs",
      component: () => import("pages/buyer/BuyerManageSKUs.vue"),
    },

    {
      path: "sales_orders",
      name: "BuyerViewSalesOrders",
      component: () => import("pages/buyer/BuyerSalesOrders.vue"),
    },
    {
      path: "sales_orders/:salesOrderId",
      name: "BuyerViewSalesOrderDetails",
      component: () => import("pages/buyer/BuyerViewSalesOrder.vue"),
    },
    {
      path: "sample_orders",
      name: "BuyerViewSampleOrders",
      component: () => import("pages/buyer/BuyerViewSampleOrders.vue"),
    },
    {
      path: "sample_orders/:sampleOrderId",
      children: [
        {
          path: "",
          name: "BuyerViewSampleOrder",
          component: () => import("pages/buyer/BuyerViewSampleOrder.vue"),
        },
      ],
    },
    {
      path: "profile",
      name: "BuyerViewProfile",
      component: () => import("pages/buyer/BuyerProfile.vue"),
    },

    {
      path: "cart",
      name: "BuyerCartPage",
      component: () => import("pages/buyer/BuyerCartPage.vue"),
    },
  ],
};

export default buyerRoutes;
