const catalogManagerRoutes = {
  path: "/catalog/",
  component: () => import("layouts/CatalogManagerLayout.vue"),
  children: [
    {
      path: "catalog/manage",
      name: "CatalogManagerManageSKUs",
      component: () => import("pages/catalog/CatalogManageSKUs.vue"),
    },
    {
      path: "sample_skus",
      name: "CatalogSampleSKUs",

      children: [
        {
          path: "",
          name: "CatalogManagerManageSampleSKUs",
          component: () =>
            import("pages/catalog/CatalogManagerManageSampleSKUs.vue"),
        },
        {
          path: "create",
          name: "CatalogManagerCreateSampleSKU",
          component: () =>
            import("pages/catalog/CatalogManagerCreateSampleSKU.vue"),
        },
        {
          path: ":sampleSKUId/edit",
          name: "CatalogManagerEditSampleSKU",
          component: () =>
            import("pages/catalog/CatalogManagerEditSampleSKU.vue"),
        },
      ],
    },
    {
      path: "profile",
      name: "CatalogManagerEditProfile",
      component: () => import("pages/catalog/CatalogProfile.vue"),
    },
    {
      path: "sellers/:sellerId/categories/:categoryId/sku/create",
      name: "CatalogManagerCreateSKU",
      component: () => import("pages/catalog/CatalogCreateSKU.vue"),
    },
    {
      path: "sellers/:sellerId/categories/:categoryId/sku/import",
      name: "CatalogManagerImportSKUs",
      component: () => import("pages/catalog/CatalogImportSKUs.vue"),
    },
    {
      path: "sellers/:sellerId/categories/:categoryId/sku/:skuId",
      name: "CatalogManagerViewSKU",
      component: () => import("pages/catalog/CatalogSKU.vue"),
    },
    {
      path: "sellers/:sellerId/categories/:categoryId/sku/:skuId/edit",
      name: "CatalogManagerEditSKU",
      component: () => import("pages/catalog/CatalogEditSKU.vue"),
    },
  ],
};

export default catalogManagerRoutes;
